import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt'
import { FaUser } from '@react-icons/all-files/fa/FaUser'
import Template from 'templates/category'

const IndexPage = props => {
  return <Template postListComponents={<PortalLink />} isHomepage={true} {...props} />
}

const PortalLink = () => {
  return (
    <StyledPortalLink href='https://hutson.dealercustomerportal.com/'>
      <FaUser className='list-icon' aria-hidden='true' focusable='false' role='presentation' />
      <span>
        Hutson Customer Portal
        <FaExternalLinkAlt
          className='external-link-icon'
          aria-hidden='true'
          focusable='false'
          role='presentation'
        />
      </span>
    </StyledPortalLink>
  )
}

const StyledPortalLink = styled.a`
  color: var(--color-n400);
  display: inline-flex;
  font-size: var(--font-size-h6);
  font-weight: 600;
  line-height: 1;
  margin: var(--size-xs) 0;
  padding: var(--size-s);
  text-decoration: none;

  :hover,
  :focus {
    span {
      text-decoration: underline;
    }
  }

  span,
  svg {
    vertical-align: middle;
  }

  .list-icon {
    color: var(--color-g400);
    margin-right: var(--size-s);
  }

  .external-link-icon {
    margin-left: var(--size-xs);
    font-size: 0.75em;
    vertical-align: baseline;
  }
`

export const pageQuery = graphql`
  query fieldGuidesPage {
    items: allSanityFieldGuideCategory(sort: { fields: [position, title], order: ASC }) {
      nodes {
        slug
        title
      }
    }
  }
`

export default IndexPage
